
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { getElementActualWidth } from '@/assets/ts/_utils';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'selected-stipend',
  components: {
    Form,
    Field,
    Datatable,
  },
  data() {
    return {
      dynamicHeading: '' as any,
      selectraineeLoad: false,
      load: false,
      componentkey: 0 as any,

      tranches_info: [],
      stipendCourseList: [],
      stipendDistricts: [],
      batch: {
        tranche_id: '',
        entity_id: '',
        start_date: '',
        end_date: '',
      },
      employee: {},
      details: {},
      poorCount: '',
      batchCount: '',
      havingAccontTraineeNumber: '',
      attendanceCompletedTraineeNumber: '',
      ethnicTraineeNumber: '',
      physicallyDisabledTraineeNumber: '',
      femaleTraineeNumber: '',
      weekplan: {},
      stipendCourseCount: 0,

      entityTypes: [],
      stipendList: [] as any,
      stipendCountList: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      componentKey: 0,
      // tableHeader: [
      //   {
      //     name: 'Sl',
      //     key: 'sl',
      //     sortable: false,
      //   },
      //   {
      //     name: 'Name',
      //     key: 'name',
      //     sortable: true,
      //     width: '150px',
      //   },
      //   {
      //     name: 'Course',
      //     key: 'course',
      //     sortable: true,
      //     width: '150px',
      //   },
      //   {
      //     name: 'Batch',
      //     key: 'batch',
      //     sortable: true,
      //   },
      //   {
      //     name: 'Bank Account & 80% Attendance',
      //     key: 'bank_account_attendence',
      //     sortable: true,
      //   },
      //   {
      //     name: 'Ethnic (A)',
      //     key: 'ethnic',
      //     sortable: true,
      //   },
      //   {
      //     name: 'Challenged (B)',
      //     key: 'challenged',
      //     sortable: true,
      //   },
      //   {
      //     name: 'Female of 64 district (C)',
      //     key: 'female_district_basis',
      //     sortable: true,
      //   },
      //   {
      //     name: 'Poor (D)',
      //     key: 'poor',
      //     sortable: true,
      //   },
      //   {
      //     name: 'Total (A+B+C+D)',
      //     key: 'total',
      //     sortable: true,
      //   },
      //   {
      //     name: 'Actions',
      //     key: 'actions',
      //     sortable: false,
      //     width: '150px',
      //   },
      // ],
      tableData: [
        {
          sl: 1,
          name: 'ABC',
          course: 'A.B.C Bangladesh-Turkish Technical School',
          batch: 'Nazirhat Municipality ,Fatickchari ',
          bank_account_attendence: 'Nazirhat Municipality ,Fatickchari ',
          ethnic: '0312552697 ',
          challenged: 'abcbdtktschool@yahoo.com',
          female_district_basis: 'www.doulatpurabchighschool.edu.bd ',
          poor: 'www.doulatpurabchighschool.edu.bd ',
          total: 'www.doulatpurabchighschool.edu.bd ',
        },
        {
          sl: 2,
          name: 'cbh',
          course: 'A.B.C Bangladesh-Turkish Technical School',
          batch: 'Nazirhat Municipality ,Fatickchari ',
          bank_account_attendence: 'Nazirhat Municipality ,Fatickchari ',
          ethnic: '0312552697 ',
          challenged: 'abcbdtktschool@yahoo.com',
          female_district_basis: 'www.doulatpurabchighschool.edu.bd ',
          poor: 'www.doulatpurabchighschool.edu.bd ',
          total: 'www.doulatpurabchighschool.edu.bd ',
        },
      ],

      Stipend_course: 0,
      total_course_num: 0 as any,
      total_batch_num: 0 as any,
      total_bank_account_trainee: 0 as any,
      ethnic_trainee: 0 as any,
      disable_trainee: 0 as any,
      female_trainee: 0 as any,
      poor_trainee: 0 as any,
      total_sum: 0 as any,

      bankplusattendancesum: 0,
      batch_completed: 0,
      ethnic_trainee_number: 0,
      female_trainee_number: 0,
      physically_disabled_trainee_number: 0,
      poor_trainee_number: 0,
      total: 0,

      loading: false,
      courseInfoData: false,
      showDetails: false,

      showtrainingProviderNotice: false,
      tableLoad: false,
      courseIds: [] as any,
    };
  },
  async created() {
    await this.getEntityInfos();
    await this.getTranches();
    await this.getDistrict();
  },
  methods: {
    async getDistrict() {
      await ApiService.get('configurations/geo_district/list')
        .then((response) => {
          this.stipendDistricts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    generateStipendCourse() {
      this.emitter.emit('stipend_course_data', this.stipendCourseList);
    },
    generateDistrict() {
      this.emitter.emit('stipend_district_data', this.stipendDistricts);
    },
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get('entity/list')
        .then((response) => {
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranches() {
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.tranches_info = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getentityListStipend() {
      if (this.batch.entity_id != null && this.batch.entity_id.length > 1) {
        this.dynamicHeading = 'Association Wise Eligible Trainees for Stipend';
      } else if (this.batch.entity_id.length == 0) {
        this.dynamicHeading = 'Association Wise Eligible Trainees for Stipend';
      } else {
        this.dynamicHeading = 'Institute Wise Eligible Trainees for Stipend';
      }

      // hanif vai call
      await ApiService.get(
        this.VUE_APP_STIPEND_COURSE_INSTITUE_LIST_API +
          '?tranche_id=' +
          this.batch.tranche_id +
          '&entity_info_id=' +
          this.batch.entity_id +
          '&start_date=' +
          this.batch.start_date +
          '&end_date=' +
          this.batch.end_date
      )
        .then((response) => {
          this.stipendList = response.data.data;
          // total count
          const sum = this.stipendList.reduce(
            (acc, cur) => {
              acc.course_num += cur.course_num;
              acc.batch_num += cur.batch_num;
              acc.bank_account_trainee += cur.bank_account_trainee;
              acc.ethnic_trainee += cur.ethnic_trainee;
              acc.disabled_trainee += cur.disabled_trainee;
              acc.female_trainee += cur.female_trainee;
              acc.poor_trainee += cur.poor_trainee;
              return acc;
            },
            {
              course_num: 0,
              batch_num: 0,
              bank_account_trainee: 0,
              ethnic_trainee: 0,
              disabled_trainee: 0,
              female_trainee: 0,
              poor_trainee: 0,
            }
          );

          this.total_course_num = sum.course_num;
          this.total_batch_num = sum.batch_num;
          this.total_bank_account_trainee = sum.bank_account_trainee;
          this.ethnic_trainee = sum.ethnic_trainee;
          this.disable_trainee = sum.disabled_trainee;
          this.female_trainee = sum.female_trainee;
          this.poor_trainee = sum.poor_trainee;
          this.total_sum =
            this.ethnic_trainee +
            this.disable_trainee +
            this.female_trainee +
            this.poor_trainee;
          this.componentKey += 1;
          this.selectraineeLoad = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getStipendCourse() {
      if (
        this.batch.tranche_id != '' &&
        this.batch.start_date != '' &&
        this.batch.end_date != ''
      ) {
        this.showDetails = true;

        this.load = true;
        this.selectraineeLoad = true;
        await this.getentityListStipend();
        await this.getStipendDetailsCount();
      } else {
        Swal.fire({
          title: 'Please check all the required field',

          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },
    async getStipendDetailsCount() {
      // shuvo api
      await ApiService.get(
        this.VUE_APP_STIPEND_COUNT_LIST_API +
          '?tranche_id=' +
          this.batch.tranche_id +
          '&entity_info_id=' +
          this.batch.entity_id +
          '&start_date=' +
          this.batch.start_date +
          '&end_date=' +
          this.batch.end_date
      )
        .then((response) => {
          this.Stipend_course = response.data.data[0]?.course_num;

          this.bankplusattendancesum =
            response.data.data[0]?.bank_account_trainee;
          this.courseIds = response.data.data[0]?.course_id;
          response.data.data[0]?.course_id;
          this.batch_completed = response.data.data?.[0]?.batch_num;
          this.ethnic_trainee_number = response.data.data?.[0]?.ethnic_trainee;
          this.female_trainee_number = response.data.data?.[0]?.female_trainee;
          this.physically_disabled_trainee_number =
            response.data.data?.[0]?.disabled_trainee;
          this.poor_trainee_number = response.data.data?.[0]?.poor_trainee;
          this.total =
            this.ethnic_trainee_number +
            this.female_trainee_number +
            this.physically_disabled_trainee_number +
            this.poor_trainee_number;
          this.getStipendCourses();
        })
        .catch(({ response }) => {
          this.load = false;
          console.log(response);
        });
    },
    async getStipendCourses() {
      await ApiService.get(
        this.VUE_APP_STIPEND_COURSE_LIST_API + '?courseId=' + this.courseIds
      )
        .then((response) => {
          // this.getStipendDetailsCount();
          this.stipendCourseList = response.data.data;
          this.stipendCourseCount = response.data.data.length;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    // courseInfo() {
    //   this.courseInfoData = true;
    // },
    // courseNotice() {
    //   this.showCourseNotice = true;
    // },
    // trainingProviderNotice() {
    //   this.showtrainingProviderNotice = true;
    // },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
